import { render, staticRenderFns } from "./VideoListItem.vue?vue&type=template&id=0be9c568&scoped=true&"
import script from "./VideoListItem.vue?vue&type=script&lang=ts&"
export * from "./VideoListItem.vue?vue&type=script&lang=ts&"
import style0 from "./VideoListItem.scss?vue&type=style&index=0&id=0be9c568&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be9c568",
  null
  
)

export default component.exports